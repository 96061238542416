        .inbox {
            .inbox__topbar {
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;
                button {
                    color: white !important;
                }
            }
        }
        
        .ql-container {
            min-height: 250px;
            // border-bottom-left-radius: 0.5em;
            // border-bottom-right-radius: 0.5em;
            // background: #fefcfc;
            p,
            code {
                font-size: 16px;
            }
        }
        
        .ql-toolbar {
            background: white;
            // background: #eaecec;
            // border-top-left-radius: 0.5em;
            // border-top-right-radius: 0.5em;
            border-bottom: none;
        }
        
        @-moz-document url-prefix() {
            textarea {
                height: 4.5em !important;
            }
        }
        
        input[type=number] {
            -moz-appearance: textfield;
        }
        
        .cssTableCells {
            font-size: 12px !important;
            padding: 6px 6px 6px 6px !important;
        }
        
        #print-area {
            // /position: fixed;
            // top: 0;
            // left: 0;
            // right: 0;
            // height: 100%;
            * {
                visibility: visible;
            }
        }
        
        @page {
            size: A6;
            margin: 0;
        }
        
        @media print {
            .receptpage-head {
                margin: 20px;
                margin-top: 50px;
                border: initial;
                border-radius: initial;
                width: initial;
                min-height: initial;
                box-shadow: initial;
                background: initial;
                page-break-after: always;
            }
            .receptpage {
                margin: 20px;
                margin-top: 150px;
                border: initial;
                border-radius: initial;
                width: initial;
                min-height: initial;
                box-shadow: initial;
                background: initial;
                page-break-after: always;
            }
        }
        
        @media only screen and (max-width: 40em) {
            .td-mobile-responcive {
                display: block !important;
                text-align: center !important;
            }
            .oga-profile {
                padding-top: 15px !important;
                margin-left: 0px !important;
            }
            .border_left {
                border-left: none !important;
            }
            .loading span {
                top: 50px !important;
            }
            .loading p {
                font-size: 14px !important;
            }
            .profile_pic_holder {
                margin-top: 0px !important;
            }
        }
        
        .border_left {
            border-left: 2px solid gray;
            height: 100% !important;
            max-height: 100% !important;
            min-height: 500px;
        }
        
        .loading {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        
        .loading span {
            position: absolute;
            height: 10px;
            width: 100px;
            top: 60px;
            overflow: hidden;
        }
        
        .loading p {
            text-align: center;
            font-size: 16px;
        }
        
        .loading span>i {
            position: absolute;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            -webkit-animation: wait 4s infinite;
            -moz-animation: wait 4s infinite;
            -o-animation: wait 4s infinite;
            animation: wait 4s infinite;
        }
        
        .loading span>i:nth-of-type(1) {
            left: -28px;
            background: #07367E;
        }
        
        .loading span>i:nth-of-type(2) {
            left: -21px;
            -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
            background: #FFC300;
        }
        
        @-webkit-keyframes wait {
            0% {
                left: -7px
            }
            30% {
                left: 52px
            }
            60% {
                left: 22px
            }
            100% {
                left: 100px
            }
        }
        
        @-moz-keyframes wait {
            0% {
                left: -7px
            }
            30% {
                left: 52px
            }
            60% {
                left: 22px
            }
            100% {
                left: 100px
            }
        }
        
        @-o-keyframes wait {
            0% {
                left: -7px
            }
            30% {
                left: 52px
            }
            60% {
                left: 22px
            }
            100% {
                left: 100px
            }
        }
        
        @keyframes wait {
            0% {
                left: -7px
            }
            30% {
                left: 52px
            }
            60% {
                left: 22px
            }
            100% {
                left: 100px
            }
        }
        
        .word_animation {
            margin-bottom: 22px;
            text-align: center;
            font-weight: 600;
        }
        
        .word_animation span span {
            position: relative;
            bottom: -1em;
            opacity: 0;
            animation: move-text 0.75s forwards;
        }
        
        @keyframes move-text {
            0% {
                bottom: -0.2em;
                opacity: 1;
            }
            50% {
                bottom: 0.2em;
            }
            100% {
                bottom: 0;
                opacity: 1;
            }
        }
        
        .limit_row {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        
        .limit_row_1 {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
        
        @media only screen and (max-width: 500px) {
            .vvoga_headding {
                text-align: "center";
                color: "#07367E";
                font-size: 1.2rem;
            }
            .vvoga_headding_sub {
                text-align: "center";
                color: "#07367E";
                font-size: 1rem;
            }
        }