body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    overflow: auto;
    position: relative;
}

#mainDiv {
    background-image: linear-gradient(to right, #07367E, #07367E) !important;
}

#createdByLL {
    color: white;
}

// .layout1 .content-wrap{
//     background-image: linear-gradient(to right, #001d4a, #123F83) !important;
//   }
// .backgroundGradient{
//       background-image: linear-gradient(to right, #001d4a, #123F83) !important;
//     }
.customStepper>span>span>svg>circle {
    color: #FFD700;
}

.customStepper>span>span>svg>path {
    color: #d3b404;
}

.customStepper>span>span>span {
    color: #ffffff !important;
}

.customStepper>span>span>svg>text {
    fill: #001d4a;
    font-weight: bold;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
    color: rgba(255, 0, 221, 0.863);
    font-size: 16px;
    // color: rgba(255, 162, 0, 0.842);
}

.navigation .nav-item .icon-text {
    display: none !important;
}

::-webkit-scrollbar {
    width: 10px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #dadada;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #c0c0c0;
    border-radius: 10px;
}

::-webkit-scrollbar-track-piece:end {
    margin-bottom: 10px;
}

::-webkit-scrollbar-track-piece:start {
    margin-top: 10px;
}

.ps__rail-y {
    background-color: transparent !important;
}

.ps__rail-y:start {
    margin-bottom: 10px;
}

.ps__rail-y:start {
    margin-top: 10px;
}

.mouse_hover_point {
    cursor: pointer;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.nav-item {
    border-radius: 50px !important;
}